.container {
    margin-left:auto;
    margin-right:auto;
    padding-left:15px;
    padding-right:15px
}
@media (min-width:544px) {
    .container {
        max-width:576px
    }
}
@media (min-width:768px) {
    .container {
        max-width:720px
    }
}
@media (min-width:992px) {
    .container {
        max-width:940px
    }
}
@media (min-width:1200px) {
    .container {
        max-width:1080px
    }
}
.login {
    min-height:85vh;
    background-position:50%;
    background-repeat:no-repeat;
    min-width:100%;
    margin-left:0;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    background-size:cover;
    color:#d8d9da
}

.container-fluid {
    margin-left:auto;
    margin-right:auto;
    padding-left:15px;
    padding-right:15px
}
.row {
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;
    margin-left:-15px;
    margin-right:-15px
}

.login-content {
    max-width:700px;
    -webkit-box-align:stretch;
    -ms-flex-align:stretch;
    align-items:stretch;
    -ms-flex-direction:row;
    flex-direction:row;
    position:relative;
    -ms-flex-pack:center;
    z-index:1;
    min-height:320px
}
.login-branding,.login-content {
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -webkit-box-pack:center;
    justify-content:center
}
.login-branding {
    width:100%;
    -webkit-box-flex:1;
    -ms-flex:1;
    flex:1;
    -ms-flex-direction:column;
    flex-direction:column;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -ms-flex-pack:center;
    -webkit-box-flex:0;
    -ms-flex-positive:0;
    flex-grow:0;
    padding-top:2rem
}
.login-branding .logo-icon {
    width:70px;
    margin-bottom:15px
}
.login-outer-box {
    overflow-y:hidden
}
.login-inner-box,.login-outer-box {
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex
}
.login-inner-box {
    text-align:center;
    padding:2rem;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    -webkit-box-flex:1;
    -ms-flex-positive:1;
    flex-grow:1;
    max-width:415px;
    -webkit-transform:tranlate(0,0);
    transform:tranlate(0,0);
    transition:.25s ease
}
.login-inner-box.add {
    -webkit-transform:translateY(-320px);
    transform:translateY(-320px)
}
.login-inner-box.add.hidden {
    display:none
}
.login-inner-box.remove {
    -webkit-transform:translateY(320px);
    transform:translateY(320px)
}
.login-inner-box.remove.hidden {
    display:none
}
.login-tab-header {
    background:#262628;
    text-align:center;
    margin-bottom:3rem
}
@media (min-width:544px) {
    .login-content {
        /*! -webkit-box-orient:horizontal; */
        /*! -webkit-box-direction:normal; */
        -ms-flex-direction:row;
        flex-direction:row;
    }
    .login-branding {
        width:35%;
        padding:4rem 2rem;
        border-right:1px solid #8daac5
    }
    .login-branding .logo-icon {
        width:160px
    }
    .login-inner-box {
        width:65%;
        padding:1rem 2rem
    }
    .login-divider .login-divider-line {
        width:110px
    }
}
@media (min-width:768px) {
    .login-content {
        /*! -webkit-box-flex:1; */
        -ms-flex:1 0 100%;
        /*! flex:1 0 100%; */
    }
    .login-branding {
        width:45%;
        padding:2rem 4rem;
        -webkit-box-flex:1;
        -ms-flex-positive:1;
        flex-grow:1
    }
    .login-branding .logo-icon {
        width:260px
    }
    .login-branding {
        font-size:3.2rem
    }
    .login-inner-box {
        width:55%;
        padding:1rem 4rem
    }
    .login-button-group {
        -webkit-box-orient:horizontal;
        -webkit-box-direction:normal;
        -ms-flex-direction:row;
        flex-direction:row
    }
    .login-button-forgot-password {
        padding-top:0;
        padding-left:10px
    }
}
@media (min-width:992px) {
    .login-form-input {
        min-width:300px
    }
}
.app-name {
    font-size: 1.5em;
    color: #000;
}
